/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-prev:before,
.slick-next:before {
  color: black;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: #0345dd #ffffff;
// }

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1px;
}

*::-webkit-scrollbar-track {
  background: #121212;
}

*::-webkit-scrollbar-thumb {
  background-color: #2979ff;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3d91ff; /* Lighter blue for hover effect */
}

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.text-gradient {
  background: #3663eb;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}
/* phoneInputStyles.css */

.PhoneInputInput {
  background-color: #27272a; /* Match your dark background */
  color: white;
  // border: 1px solid #3F3F46; /* Border to match your theme */
  // border-radius: 8px;
  padding: 10px 14px;
}

.PhoneInputInput:focus {
  outline: none;
  border-color: #007aff; /* Border color on focus */
}

.PhoneInputCountry {
  background-color: #27272a; /* Match your dark background */
  color: white;
  border: 1px solid #3f3f46; /* Border to match your theme */
  border-radius: 8px 0 0 8px;
  padding: 10px 14px;
}

.PhoneInputCountry select {
  background-color: #27272a; /* Match your dark background */
  color: white;
  border: none;
}

.PhoneInputCountry select:focus {
  outline: none;
  border-color: #007aff; /* Border color on focus */
}

.PhoneInputCountryIcon {
  fill: white; /* Icon color */
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.glow {
  /* font-size: 80px; */
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  text-shadow: 0 0 100px rgb(255, 215, 54), 0 0 100px rgba(65, 49, 241, 0.705),
    0 0 30px #436af6, 0 0 40px #d7e446, 0 0 50px #d3e600, 0 0 60px #ade600,
    0 0 70px #e60073;
}
.text-out {
  fill: none;
  stroke-width: 0.2px;
  stroke-linejoin: round;
  opacity: 0.5;
}
/* @keyframes glow {
  from {
    text-shadow: 0 0 100px rgb(255, 215, 54), 0 0 100px rgba(65, 49, 241, 0.705), 0 0 30px #436af6, 0 0 40px #d7e446, 0 0 50px #d3e600, 0 0 60px #ade600, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 200px rgb(241, 255, 48), 0 0 100px #534dff, 0 0 40px #fffc32, 0 0 50px #6239f6ca, 0 0 60px #c4ff4d, 0 0 70px #8bff4d, 0 0 80px #ff4da6;
  }
} */

.scroll-downs {
  /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
  margin: auto;
  z-index: 50;
  width: 34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.item {
  margin: 0 auto;
  padding: 1em;
  position: relative;
  overflow: hidden;
}
.item .img-wrap:before {
  content: "";
  background-image: linear-gradient(
    360deg,
    #181818 10%,
    rgba(24, 24, 24, 0) 100%
  );
  position: absolute;
  height: 200px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}
.item .img-wrap:after {
  content: "";
  display: block;
}
.img-wrap img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  box-shadow: 0px 2px 10px 0px rgba(23, 20, 20, 0.2);
  -ms-interpolation-mode: bicubic;
}
.heroBackground {
  background: url("./Assets/hero_bg.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-position: "right";
  background-size: "cover";
  background-repeat: "no-repeat"; */
  background-blend-mode: "lighten";

  width: 100%;
  /* height: auto; */
}
/* .premium,
.publisher {
  background: radial-gradient(
        circle at 100% 100%,
        #181818 0,
        #181818 29px,
        transparent 29px
      )
      0% 0%/30px 30px no-repeat,
    radial-gradient(circle at 0 100%, #181818 0, #181818 29px, transparent 29px)
      100% 0%/30px 30px no-repeat,
    radial-gradient(circle at 100% 0, #181818 0, #181818 29px, transparent 29px)
      0% 100%/30px 30px no-repeat,
    radial-gradient(circle at 0 0, #181818 0, #181818 29px, transparent 29px)
      100% 100%/30px 30px no-repeat,
    linear-gradient(#181818, #181818) 50% 50% / calc(100% - 2px)
      calc(100% - 60px) no-repeat,
    linear-gradient(#181818, #181818) 50% 50% / calc(100% - 60px)
      calc(100% - 2px) no-repeat,
    linear-gradient(90deg, #181818 0%, #d5e0e6 100%);
  border-radius: 30px;
  box-sizing: "border-box";
} */

.carousel-container {
  background: #181818;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.carousel-item {
  height: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}

@media screen and (max-width: 450px) {
  .heroBackground {
    background-image: url("./Assets/mobile_bg.png");
    height: fit-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: contain;
    background-position: "left";
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 800px) {
  .premium,
  .publisher {
    background: none;
    border: none;
  }
}

@media screen and (min-width: 1450px) {
  .premium,
  .publisher {
    background: radial-gradient(
          circle at 100% 100%,
          #181818 0,
          #181818 29px,
          transparent 29px
        )
        0% 0%/30px 30px no-repeat,
      radial-gradient(
          circle at 0 100%,
          #181818 0,
          #181818 29px,
          transparent 29px
        )
        100% 0%/30px 30px no-repeat,
      radial-gradient(
          circle at 100% 0,
          #181818 0,
          #181818 29px,
          transparent 29px
        )
        0% 100%/30px 30px no-repeat,
      radial-gradient(circle at 0 0, #181818 0, #181818 29px, transparent 29px)
        100% 100%/30px 30px no-repeat,
      linear-gradient(#181818, #181818) 50% 50% / calc(100% - 2px)
        calc(100% - 60px) no-repeat,
      linear-gradient(#181818, #181818) 50% 50% / calc(100% - 60px)
        calc(100% - 2px) no-repeat,
      linear-gradient(90deg, #181818 0%, #d5e0e6 100%);
    border-radius: 30px;
    box-sizing: "border-box";
  }
}

.arrows {
  width: 60px;
  height: 72px;
  /* position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px; */
}

.arrows path {
  stroke: #2994d1;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}

.cool-link {
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #d1d5db;
  transition: width 0.3s;
}

.cool-link:hover::after {
  width: 100%;
  transition: width 0.3s;
}

#linehead {
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1394px) {
  #home {
    max-width: 90%;
    margin: auto;
    margin-top: 5rem;
  }
}
.mantine-Accordion-panel {
  padding: 0;
}
.mantine-ukbbnm {
  padding: 0;
}
.mantine-idox8n {
  padding: 12px 8px;
}
span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(7) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(8) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(9) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
@media (min-width: 640px) {
  .slider {
    // background: white;
    // box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 95%;

    &::before,
    &::after {
      // @include white-gradient;
      content: "";
      height: 100px;
      position: absolute;
      width: 100px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(250px * 14);
    }

    // .slide {
    // height: 100px;
    // width: 100px;
    // }
  }
}
